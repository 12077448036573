
import { formatMSGAClusters, msga_property_pins } from "~/components/map/MapBoxConfig";
import { onClickPropertyPin } from "~/helpers/map-helpers.js";
import SymbolLayer from "~/components/map/layers/basicLayers/SymbolLayer.vue";
import MapPositionUpdateLayer from "~/components/map/layers/basicLayers/MapPositionUpdateLayer.vue";

import LayoutResultsSplit from "./components/LayoutResultsSplit.vue";
import FilterNaked from "./components/FilterNaked.vue";
import Pagination from "./components/Pagination.vue";
import AddToListDialog from "~/components/list/AddToListDialog.vue";

import ResultCards from "./components/ResultCards.vue";
import ResultTable from "./components/ResultTable.vue";
import MapToggle from "./components/MapToggle.vue";

import { easeToDefault } from "~/helpers/map-helpers.js";
import { getPropertyAddress } from "~/helpers/property-helpers.js";
import { mapPropertyKeyFigures } from "~/helpers/search-helpers.js";
import { mapPropertyRemarks, getPropertyTypeText } from "~/helpers/property-helpers.js";
import { copySearchResultsToClipboard } from "~/helpers/clipboard-helpers.js";
import {
  mergeFiltersWithMetadata,
  mapGlobalFilters,
  mapValuationFilters,
  mapOwnerFilters,
  mapPropertyFilters,
  filterSupportedFilters,
  FreeTextFilter,
} from "~/helpers/filter-helpers.js";

import SearchFilters from "~/graphql/Search/SearchFilters.gql";
import AdvancedSearch from "~/graphql/Search/AdvancedSearch.gql";
import SearchFiltersMetadata from "~/graphql/Search/SearchFiltersMetadata.gql";
import AdvancedSearchForLists from "~/graphql/Search/AdvancedSearchForLists.gql";
import AdvancedSearchTotalResults from "~/graphql/Search/AdvancedSearchTotalResults.gql";
import WidgetBlank from "../../components/Widget/WidgetBlank.vue";

import { mapState, mapWritableState } from "pinia";
import { useChromeStore } from "~/composables/stores/chrome";

export default {
  components: {
    SymbolLayer,
    MapPositionUpdateLayer,
    LayoutResultsSplit,
    FilterNaked,
    AddToListDialog,
    Pagination,
    ResultCards,
    ResultTable,
    MapToggle,
    WidgetBlank,
  },

  apollo: {
    totalResults: {
      query: AdvancedSearchTotalResults,
      variables: function () {
        return {
          input: this.searchQuery,
        };
      },
      update(resp) {
        return resp.totalResults;
      },
      skip() {
        return this.searchQuery == null || this.filterMetadataLoaded == false;
      },
    },

    filters: {
      query: SearchFilters,
      update(resp) {
        return resp.filters;
      },
      skip() {
        return this.$store.getters["msga/getFilters"].length != 0;
      },
    },

    filtersMetadata: {
      query: SearchFiltersMetadata,
      variables: function () {
        return {
          input: this.availableFilters.filter((f) => !f.metadataLoaded).map((f) => f.id),
        };
      },
      skip() {
        return this.recommendedFilters == null;
      },
    },
  },

  data() {
    return {
      search: null,
      cancelableSearchObservable: null,
      loading: false,
      msga_property_pins: msga_property_pins,
      resultsPerPage: 10,
      pageChangeDebounce: null,
      resultsQuery: null,
      pageChangeDebounceTime: 600,
      displayMode: "cards",
      addFiltersVisible: false,
      propertiesToSave: [],
      showAddToList: false,
      resizeSensor: null,
      changedFilters: [],
      forceRenderKey: 0,
      propertyKeyFigureLabels: [
        {
          datasetKey: "constructionYears",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_CONSTRUCTION_YEAR"),
        },
        {
          datasetKey: "extensionYears",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_EXTENSION_YEAR"),
        },
        {
          datasetKey: "latestPurchaseDate",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LATEST_PURCHASE_DATE"),
        },
        {
          datasetKey: "latestPurchaseSum",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LATEST_PURCHASE_SUM"),
        },
        {
          datasetKey: "weightedAreas",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_WEIGHTED_AREA"),
        },
        {
          datasetKey: "squareMeterPrice",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_SQUAREMETER_PRICE"),
        },
        {
          datasetKey: "landValuation",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_LAND_VALUATION"),
        },
        {
          datasetKey: "propertyValuation",
          translation: this.$t("REFERENCE_PROPERTIES_LABEL_KEY_FIGURE_PROPERTY_VALUATION"),
        },
      ],

      companyKeyFigureLabels: [
        {
          datasetKey: "established",
          translation: this.$t("COMPANY_INTRO_LABEL_ESTABLISHED"),
        },
        {
          datasetKey: "branchText",
          translation: this.$t("COMPANY_CARD_INFO_INDUSTRY_TYPE"),
        },
        {
          datasetKey: "typeText",
          translation: this.$t("COMPANY_CARD_INFO_INDUSTRY_TYPE"),
        },
      ],

      personKeyFigureLabels: [
        {
          datasetKey: "age",
          translation: this.$t("EXPLORE_SEARCH_NEW_PERSON_KEYFIGURES_AGE"),
        },
        {
          datasetKey: "address",
          translation: this.$t("PERSON_PAGE_ADDRESS"),
        },
        {
          datasetKey: "gender",
          translation: this.$t("EXPLORE_SEARCH_NEW_PERSON_KEYFIGURES_GENDER"),
        },
      ],
    };
  },

  computed: {
    ...mapState(useChromeStore, {
      storeLoading: "loading",
    }),

    ...mapWritableState(useChromeStore, ["splitPercentage", "splitPercentageBarOmit"]),

    filtersMetadataLoading() {
      return this.$apollo.queries.filtersMetadata.loading;
    },
    propertyTypes() {
      return {
        "Main Property": this.$t("COMPANY_LABEL_TYPE_MAIN_PROPERTY"),
        "Condo": this.$t("COMPANY_LABEL_TYPE_CONDO"),
        "Building on foreign plot": this.$t("COMPANY_LABEL_TYPE_BUILDING_ON_FOREIGN_PLOT"),
      };
    },

    results() {
      if (this.search == null) return [];

      return this.search.hits.filter((hit) => hit.entity != null).map((result) => this.mapProperty(result));
    },

    resultState: {
      get() {
        return this.$store.getters["msga/getResultState"];
      },
      set(value) {
        this.$store.commit("msga/setResultState", value);
      },
    },

    selectedPropertyType: {
      get() {
        return this.$store.getters["msga/getSelectedPropertyType"];
      },
      set(value) {
        this.$store.commit("msga/setSelectedPropertyType", value);
      },
    },

    searchQuery() {
      return this.$store.getters["msga/getSearchQuery"];
    },

    clusters() {
      return this.$store.getters["msga/getClusters"];
    },

    resultsCurrentPage() {
      return this.$store.getters["msga/getOffset"] / this.resultsPerPage;
    },

    currentFilters() {
      return this.$store.getters["msga/getFilters"];
    },

    globalFilters() {
      const filters = this.$store.getters["msga/getFilters"];

      return mapGlobalFilters(filters);
    },

    propertyFilters() {
      const filters = this.$store.getters["msga/getFilters"];
      return mapPropertyFilters(filters).find((f) => f.id === this.selectedPropertyType)?.filters ?? [];
    },

    valuationFilters() {
      const filters = this.$store.getters["msga/getFilters"];

      return mapValuationFilters(filters);
    },

    ownerFilters() {
      const filters = this.$store.getters["msga/getFilters"];

      return mapOwnerFilters(filters);
    },

    availableFilters() {
      return [...this.recommendedFilters, ...this.supportedFilters];
    },

    recommendedFilters() {
      if (this.filters == null) return [];

      return mapGlobalFilters(this.filters);
    },

    supportedFilters() {
      if (this.filters == null) return [];

      return filterSupportedFilters(this.filters);
    },

    filterMetadataLoaded() {
      const filters = this.$store.getters["msga/getFilters"];

      if (filters == null || filters.length == 0) return false;

      return filters.every((filter) => filter.metadataLoaded);
    },

    saveSearchDisabled() {
      return this.totalResults > 1000;
    },

    displayModeLabel() {
      return this.displayMode === "table" ? this.$t("DISPLAY_MODE_TABLE") : this.$t("DISPLAY_MODE_CARDS");
    },
  },

  watch: {
    results() {
      this.scrollToTop();
    },

    totalResults(val, prev) {
      if (prev != null && val != null) {
        if (this.$store.getters["mapstate/showAdvanvcedSearchResultsPins"] == undefined) {
          this.$store.commit("mapstate/showAdvanvcedSearchResultsPins", true);
        }
      }
    },

    filtersMetadata(val) {
      if (val == null) return;

      if (this.filters == null || this.filters.length == 0) return;

      // Update filters with metadata
      const updatedFilters = mergeFiltersWithMetadata(this.filters, val);

      // Lastly add the free text filter
      updatedFilters.push(FreeTextFilter);

      const filtersWithMetadata = updatedFilters.filter((f) => f.metadataLoaded);

      filtersWithMetadata.forEach((f) => this.$store.commit("msga/upsertFilter", { ...f }, true));
    },

    searchQuery(val) {
      if (val == null) return;

      this.makeSearch(val);
    },
  },

  async mounted() {
    window.addEventListener("resize", this.updateSplitPercentage);

    this.splitPercentageBarOmit = true;

    if (this.resultState == "list") {
      this.$store.commit("mapstate/mapOmit", true);
    } else {
      this.$store.commit("mapstate/mapOmit", false);
    }

    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));

      this.$map.then((map) => {
        const center = this.$store.getters["msga/getMapCenter"];
        const zoom = this.$store.getters["msga/getMapZoom"];

        if (center != null && zoom != null) {
          map.easeTo({
            center,
            zoom,
          });
        } else {
          this.easeToDefault();
        }

        if (this.searchQuery != null) {
          this.makeSearch(this.searchQuery);
        }
      });
    }, 100);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.updateSplitPercentage);

    this.$map.then((map) => {
      const center = map.getCenter();
      const zoom = map.getZoom();

      this.$store.commit("msga/setMapCenter", center);
      this.$store.commit("msga/setMapZoom", zoom);
    });
  },

  methods: {
    formatMSGAClusters,
    onClickPropertyPin,
    easeToDefault,

    makeSearch(query) {
      // Set loading state
      this.loading = true;

      // Cancel any previous search
      if (this.cancelableSearchObservable != null) {
        this.cancelableSearchObservable.unsubscribe();
      }

      // Perform search
      const searchPromise = this.$apollo.watchQuery({
        query: AdvancedSearch,
        variables: {
          input: query,
          transactionFilter: {
            limit: 1,
            offset: 0,
            orderDirection: "desc",
            orderBy: "HandoverDate",
          },
          valuationFilter: {
            limit: 1,
            offset: 0,
            orderDirection: "desc",
            orderBy: "Year",
          },
        },
      });

      // Update search results
      this.cancelableSearchObservable = searchPromise.subscribe(({ data }) => {
        this.search = data.search;
        this.loading = false;
        this.cancelableSearchObservable = null;
      });
    },

    scrollToTop() {
      const element = document.querySelectorAll(".layout-results-results")[0];

      if (element) {
        element.scrollTop = 0;
      }
    },

    pageNext() {
      this.$store.commit("msga/setOffset", (this.resultsCurrentPage + 1) * this.resultsPerPage);
      this.$store.commit("msga/search");
    },

    pagePrevious() {
      this.$store.commit("msga/setOffset", (this.resultsCurrentPage - 1) * this.resultsPerPage);
      this.$store.commit("msga/search");
    },

    pageChange(n) {
      clearTimeout(this.pageChangeDebounce);

      this.pageChangeDebounce = setTimeout(() => {
        this.$store.commit("msga/setOffset", (n - 1) * this.resultsPerPage);
        this.$store.commit("msga/search");
      }, this.pageChangeDebounceTime);
    },

    mapProperty(searchResult) {
      const property = structuredClone(searchResult.entity);
      property.type = getPropertyTypeText(property);

      return {
        id: searchResult.id,
        displayName: searchResult.text,
        typeOf: searchResult.typeOf,
        bfeNumber: property.bfeNumber,
        addressText: getPropertyAddress(property) ? getPropertyAddress(property).address : this.$t("NO_ACCESS_ADDRESS"),
        typeText: getPropertyTypeText(property),
        keyFigures: mapPropertyKeyFigures(searchResult, this.$options.filters),
        keyFigureLabels: this.propertyKeyFigureLabels,
        remarks: mapPropertyRemarks(property),
        owners: searchResult.entity.owners,
        administrators: searchResult.entity.administrators,
        isSavedOnList: searchResult.entity.listProperties != null,
        lists: this.getListsForEntity(searchResult.entity),
      };
    },

    getListsForEntity(entity) {
      return entity.listProperties?.map((listProperty) => listProperty.list) ?? [];
    },

    addToList(result) {
      if (result.typeOf === "PROPERTY") {
        this.propertiesToSave = [result.bfeNumber];
        this.showAddToList = true;
      }
    },

    copyToClipboard(keyFigureLabels, keyFigures, addressText) {
      copySearchResultsToClipboard(keyFigureLabels, keyFigures, addressText);
    },

    refetch() {
      this.makeSearch(this.searchQuery);
    },

    async saveSearchToList() {
      const filter = {
        input: { ...this.searchQuery },
      };

      filter.input.offset = 0;
      filter.input.limit = this.totalResults;

      const result = await this.$apollo.query({
        query: AdvancedSearchForLists,
        variables: filter,
      });

      const bfes = result.data.search.hits.map((hit) => {
        return { bfeNumber: hit?.entity?.bfeNumber };
      });

      this.propertiesToSave = bfes;
      this.showAddToList = true;
    },

    updateSplitPercentage() {
      let r = getComputedStyle(document.documentElement).getPropertyValue("--layout-results-split-left-width").replace(" ", "").replace("rem", "");

      const w = window.innerWidth;

      if (w < 1441) {
        r = r * 14;
      } else {
        r = r * 16;
      }

      this.splitPercentage = (r / window.innerWidth) * 100;
    },

    async mapToggleStateClick() {
      if (this.resultState === "list") {
        this.$store.commit("mapstate/mapOmit", false);

        this.splitPercentageBarOmit = true;

        window.dispatchEvent(new Event("resize"));

        this.resultState = "map";
      } else {
        const splitPercentagePreference = await this.$userPreferences.getKey("preferencesNamespaceChrome", "splitPercentage");

        this.$store.commit("mapstate/mapOmit", true);

        this.splitPercentageBarOmit = false;
        this.splitPercentage = splitPercentagePreference;

        window.dispatchEvent(new Event("resize"));

        this.resultState = "list";
      }
    },

    filterValueChanged(event) {
      const index = this.changedFilters.findIndex((f) => f.filter === event.filter);

      if (event.value) {
        if (index === -1) this.changedFilters.push(event);
      } else {
        if (index !== -1) this.changedFilters.splice(index, 1);
      }
    },

    getChangedFilters(filters) {
      return filters.filter((f) => this.changedFilters.findIndex((cf) => cf.filter === f.id) !== -1).length;
    },
  },
};
